//
//  Vanilla Lazyload images | https://github.com/verlok/lazyload
//  _______________________________________________________


// Advanced and best option: conditionally load version 8 or 10
(function(w, d){
    let b = d.getElementsByTagName('body')[0];
    let s = d.createElement("script"); s.async = true;
    let v = !("IntersectionObserver" in w) ? "8.7.1" : "10.5.2";
    s.src = "https://cdnjs.cloudflare.com/ajax/libs/vanilla-lazyload/" + v + "/lazyload.min.js";
    w.lazyLoadOptions = {}; // Your options here. See "recipes" for more information about async.
    b.appendChild(s);
}(window, document));