//
//  Back To Top
//  _______________________________________________________

// fade in page scroll icon
if ( $('.backToTop').length ) {
    
    $(document).scroll(function() {

        var $minH = $(window).height() * 2.25;
        var $vPos = $(this).scrollTop();

        if ( $vPos > $minH ) {
            $('.backToTop').fadeIn();
        } else {
            $('.backToTop').fadeOut(200);
        }
    });
}

// scroll to top
$('a[href="#body-inner"]').on('click',function (e) {
    e.preventDefault();

    var target = this.hash;
    var $target = $(target);

    $('html, body').stop().animate({
        'scrollTop': $target.offset().top
    }, 500, 'swing', function () {
        window.location.hash.replace('#','');
    });
});