let jq = './jquery/';
let es = './es6/';


// enterprise menu
// require( es + 'entMenu');

// search
// require( es + 'entSearch');

// local menu
// require( es + 'localMenu');

// get current date
require( es + 'currentDate');

// add rel="noopener noreferrer" to all a[target="_blank"]
require( es + 'noopener');

// form select link
require( es + 'formSelectLink');

// lazyload YouTube videos
// removed to test script conflict
// require( es + 'lazyYoutube');

// lazyload images
require( es + 'vanilla-lazyload');



// smooth page scroll
require( jq + 'pageScroll.js');

// back to top
require( jq + 'backToTop.js');

// profiles modal
require( jq + 'profilesModal.js');



// featherlight
require( jq + 'vendor/featherlight.js');

// jssocial
require( jq + 'vendor/jssocials.js');

// mmenu
require( jq + 'vendor/mmenu.js');


// ADD COMPONENTS JS
require( './components.js');


// ADD PLUGINS JS
require( './plugins.js');
