//
//   jsSocials share config
//  _______________________________________________________
require('jssocials');


// register print icon and functionality
jsSocials.shares.print = {
    label: "print",
    logo: "fas fa-print",
    shareUrl: "javascript:window.print()",
    countUrl: "",
    getCount: function(data) {}
};

// register follow icons and functionality
jsSocials.shares.followFacebook = {
    label: "Follow on Facebook",
    logo: "fab fa-facebook-f",
    shareUrl: "https://www.facebook.com/Johns.Hopkins.Medicine",
    countUrl: "",
    getCount: function(data) {}
};

jsSocials.shares.followTwitter = {
    label: "Follow on Twitter",
    logo: "fab fa-twitter",
    shareUrl: "https://twitter.com/HopkinsMedicine",
    countUrl: "",
    getCount: function(data) {}
};

jsSocials.shares.followGoogle = {
    label: "Follow on Google+",
    logo: "fab fa-google-plus-g",
    shareUrl: "https://plus.google.com/+HopkinsmedicineOrg",
    countUrl: "",
    getCount: function(data) {}
};

jsSocials.shares.followLinkedin = {
    label: "Follow on Linkedin",
    logo: "fab fa-linkedin-in",
    shareUrl: "https://www.linkedin.com/company/johns-hopkins-medicine",
    countUrl: "",
    getCount: function(data) {}
};

jsSocials.shares.followYouTube = {
    label: "Follow on YouTube",
    logo: "fab fa-youtube",
    shareUrl: "https://www.youtube.com/user/JohnsHopkinsMedicine?sub_confirmation=1",
    countUrl: "",
    getCount: function(data) {}
};

jsSocials.shares.followInstagram = {
    label: "Follow on Instagram",
    logo: "fab fa-instagram",
    shareUrl: "https://www.instagram.com/HopkinsMedicine/",
    countUrl: "",
    getCount: function(data) {}
};

// build share object
$("#share").jsSocials({

    shares: [
        {
            share: 'facebook',
            logo: 'fab fa-facebook'
        },
        {
            share: 'twitter',
            logo: 'fab fa-twitter'
        },
        {
            share: 'googleplus',
            logo: 'fab fa-google'
        },
        {
            share: 'linkedin',
            logo: 'fab fa-linkedin'
        },
        {
            share: 'pinterest',
            logo: 'fab fa-pinterest-p'
        },
        {
            share: 'email',
            logo: 'far fa-envelope'
        },
        {
            share: 'print'
        }
    ],
    showLabel: false,
});

// build follow object
$("#follow").jsSocials({

    shares: [
        {
            share: 'followFacebook',
            css: "jssocials-share-facebook"
        },
        {
            share: 'followTwitter',
            css: "jssocials-share-twitter"
        },
        {
            share: 'followLinkedin',
            css: "jssocials-share-linkedin",
        },
        {
            share: 'followGoogle',
            css: "jssocials-share-googleplus",
        },
        {
            share: 'followYouTube',
            css: "jssocials-share-youtube",
        },
        {
            share: 'followInstagram',
            css: "jssocials-share-instagram",
        }
    ],
    showLabel: false,
});

// build follow object
$("#follow-footer").jsSocials({

    shares: [
        {
            share: 'followFacebook',
            css: "jssocials-share-facebook"
        },
        {
            share: 'followTwitter',
            css: "jssocials-share-twitter"
        },
        {
            share: 'followLinkedin',
            css: "jssocials-share-linkedin",
        },
        {
            share: 'followInstagram',
            css: "jssocials-share-instagram",
        },
        {
            share: 'followYouTube',
            css: "jssocials-share-youtube",
        }
    ],
    showLabel: false,
});


(function() {
    setTimeout(function() {

        // accessibility for share
        $('[id^="share"] .jssocials-share-link').attr('rel', 'noopener noreferrer');
        $('[id^="share"] .jssocials-share-logo').attr('aria-hidden', 'true');
        $('[id^="share"] .jssocials-share-facebook a').attr('aria-label', 'Share on Facebook');
        $('[id^="share"] .jssocials-share-twitter a').attr('aria-label', 'Share on Twitter');
        $('[id^="share"] .jssocials-share-googleplus a').attr('aria-label', 'Share on Google+');
        $('[id^="share"] .jssocials-share-linkedin a').attr('aria-label', 'Share on LinkedIn');
        $('[id^="share"] .jssocials-share-pinterest a').attr('aria-label', 'Share on Pinterest');
        $('[id^="share"] .jssocials-share-youtube a').attr('aria-label', 'Share on YouTube');
        $('[id^="share"] .jssocials-share-instagram a').attr('aria-label', 'Share on Instagram');
        $('[id^="share"] .jssocials-share-email a').attr('aria-label', 'Share via Email');
        $('[id^="share"] .jssocials-share-print a').attr('aria-label', 'Print this Article');

        // accessibility for follow
        $('[id^="follow"] .jssocials-share-link').attr('rel', 'noopener noreferrer');
        $('[id^="follow"] .jssocials-share-logo').attr('aria-hidden', 'true');
        $('[id^="follow"] .jssocials-share-facebook a').attr('aria-label', 'Follow on Facebook');
        $('[id^="follow"] .jssocials-share-twitter a').attr('aria-label', 'Follow on Twitter');
        $('[id^="follow"] .jssocials-share-googleplus a').attr('aria-label', 'Follow on Google+');
        $('[id^="follow"] .jssocials-share-linkedin a').attr('aria-label', 'Follow on LinkedIn');
        $('[id^="follow"] .jssocials-share-pinterest a').attr('aria-label', 'Follow on Pinterest');
        $('[id^="follow"] .jssocials-share-youtube a').attr('aria-label', 'Follow on YouTube');
        $('[id^="follow"] .jssocials-share-instagram a').attr('aria-label', 'Follow on Instagram');

    }, 500);
})();
