//
//  Performance and Security
//  _______________________________________________________


(() => {

    let target = document.querySelector('a[target="_blank"]');

    if (target) {
        target.setAttribute('rel', 'noopener noreferrer');
    }
})();