//
//   Swiper - Image w/ Caption config
//  _______________________________________________________

import Swiper from 'swiper/dist/js/swiper.min.js';


let sliderImageCaption = new Swiper('[data-slider-type="image-caption"]', {

    on: {
        init: function() {
            $('.image-caption-slide .caption a').attr('tabindex', '-1');
            $('.image-caption-slide-active .caption a').attr('tabindex', '0').focus();
        }, 

        slideChangeTransitionEnd: function() {
            $('.image-caption-slide .caption a').attr('tabindex', '-1');
            $('.image-caption-slide-active .caption a').attr('tabindex', '0').first().focus();
        },
    }, 

    // Optional parameters
    direction: 'horizontal',
    loop: true,

    // Accessibility
    a11y: true,   

    // Keyboard Control 
    keyboard: {
        enabled: true,
        // onlyInViewport: false,
    },    

    // Navigation arrows
    navigation: {
        nextEl: '.image-caption-button-next',
        prevEl: '.image-caption-button-prev',
    },

    // Namespacing
    containerModifierClass: 'slider-image-caption-',
    slideClass: 'image-caption-slide',
    slideActiveClass: 'image-caption-slide-active',
    slideDuplicatedActiveClass: 'image-caption-slide-duplicate-active',
    slideVisibleClass: 'image-caption-slide-visible',
    slideDuplicateClass: 'image-caption-slide-duplicate',
    slideNextClass: 'image-caption-slide-next',
    slideDuplicatedNextClass: 'image-caption-slide-duplicate-next',
    slidePrevClass: 'image-caption-slide-prev',
    slideDuplicatedPrevClass: 'image-caption-slide-duplicate-prev',
    wrapperClass: 'image-caption-wrapper'

});

$('.swiper-notification').addClass('sr-only');
