//
//  Get current Date
//  _______________________________________________________

(() => {

    let date = document.querySelector('.currentDate'),
        currentDate = new Date().getFullYear();

    if (date) {
        date.appendChild(document.createTextNode(currentDate));
    }
})();