//
//   Featherlight Lightbox  config
//  _______________________________________________________
require('featherlight-webpack/src/featherlight.js');


if ( ($(window).width() > 720 ) ) { 

    //  options
    $.extend($.featherlight.defaults, {
        closeIcon: '&#xe5cd;',  // Close icon | Material Design : close : unicode
        afterContent: function(event){

            var $caption = this.$currentTarget.find('figcaption').text();

            this.$instance.find('.featherlight-caption').remove();
            $('<div class="featherlight-caption">').text( $caption ).appendTo(this.$instance.find('.featherlight-content'));
            this.$instance.find('.featherlight-image, .featherlight-caption').wrapAll('<div class="rel-wrap">');
        }
    });

} else {

    $('a[data-featherlight]').removeAttr('href');
}