//
//   MMenu
//  _______________________________________________________
require('jquery.mmenu/dist/jquery.mmenu.js');
require('jquery.mmenu/dist/addons/autoheight/jquery.mmenu.autoheight.js');
require('jquery.mmenu/dist/addons/keyboardnavigation/jquery.mmenu.keyboardnavigation.js');
require('jquery.mmenu/dist/addons/setselected/jquery.mmenu.setselected.js');


$('#loc-mmenu-nav').mmenu({

    // options
    slidingSubmenus: true,
    offCanvas: false,

    setSelected: {
        current: 'detect',
        hover: true
    },

    navbar: {
      titleLink: 'none'
    },

    screenReader: {
       // screenReader options
       aria: true,
       text: true,
    },

    "autoHeight": true

}, {

    //configuration


});