//
//   Swiper - Stats config
//  _______________________________________________________

import Swiper from 'swiper/dist/js/swiper.min.js';


let sliderStats = new Swiper('[data-slider-type="stats"]', {

    // Optional parameters
    direction: 'horizontal',
    loop: true,

    // Accessibility
    a11y: true,

    // Keyboard Control 
    keyboard: {
        enabled: true,
        // onlyInViewport: false,
    },

    // Pagination
    pagination: {

        el: '.stats-pagination',

        //bullets
        bulletClass: 'stats-pagination-bullet',
        bulletActiveClass: 'stats-pagination-bullet-active',
        modifierClass: 'stats-pagination-',
        currentClass: 'stats-pagination-current',
        totalClass: 'stats-pagination-total',
        hiddenClass: 'stats-pagination-hidden',
        progressbarFillClass: 'stats-pagination-progressbar-fill',
        clickableClass: 'stats-pagination-clickable'

    },

    // Navigation arrows
    navigation: {
        nextEl: '.stats-button-next',
        prevEl: '.stats-button-prev',
    },

    // Namespacing
    containerModifierClass: 'slider-stats-',
    slideClass: 'stats-slide',
    slideActiveClass: 'stats-slide-active',
    slideDuplicatedActiveClass: 'stats-slide-duplicate-active',
    slideVisibleClass: 'stats-slide-visible',
    slideDuplicateClass: 'stats-slide-duplicate',
    slideNextClass: 'stats-slide-next',
    slideDuplicatedNextClass: 'stats-slide-duplicate-next',
    slidePrevClass: 'stats-slide-prev',
    slideDuplicatedPrevClass: 'stats-slide-duplicate-prev',
    wrapperClass: 'stats-wrapper'

});
