let jq = './jquery/components/';
let es = './es6/';


// hero bkgd-img
require( jq + 'heroBkgdImg.js');

// hero sticky nav
require( jq + 'heroNav.js');

// research lab viewlets
require( jq + 'researchLabViewlets.js');

// swiper
require( es + 'vendor/sliders-swiper/_sliders.js');