//
//  Hero Background Image from <img>
//  _______________________________________________________


var $heroBkgdImg = $('[class^="hero-"] img[data-type="hero-bkgd-img"]');
var $parallax = $('[class^="hero-"] img[data-type="hero-bkgd-img--parallax"]');
var $full = $('.hero-bkgd--full img[data-type="hero-bkgd-img--full"]');


// default
$heroBkgdImg.each(function(i, elem) {

    var img = $(elem);
    var target = $(this).closest('.hero-bkgd');

    $(target).css({
        background: "url(" + img.attr("src") + ") no-repeat center center",
        'background-size': 'cover',
        width: '100%',
        height: 'auto'
    });

    target.attr('data-type', 'hero-bkgd-img');
    img.replaceWith();

});


// temp parallax
$parallax.each(function(i, elem) {

    var img = $(elem);
    var target = $(this).closest('.hero-bkgd');

    $(target).css({
        background: "url(" + img.attr("src") + ") scroll no-repeat center center",
        'background-size': 'cover',
        'background-attachment': 'fixed', //parallax
        width: '100%',
        height: 'auto'
    });

    target.attr('data-type', 'hero-bkgd-img--parallax');
    img.replaceWith();

});



// full image width & height set as max-height
if ( $(window).width() >= 721 ) {

    $full.each(function(i, elem) {

        var img = $(elem);
        var imgH = Math.floor( img.height() );
        var target = $(this).closest('.hero-bkgd--full');
        
        $(target).css({
            background: "url(" + img.attr("src") + ") no-repeat center center",
            'background-size': 'cover',
            'width': '100%',
            // 'max-height': imgH
        });

        target.attr('data-type', 'hero-bkgd-img--full');
        img.replaceWith();

    });

} else {

    $full.each(function(i, elem) {

        var img = $(elem);
        var target = $(this).closest('.hero-bkgd--full'); 

        $(target).css({
            background: "url(" + img.attr("src") + ") no-repeat center center",
            'background-size': 'cover',
        });

        target.attr('data-type', 'hero-bkgd-img--full');
        img.replaceWith();

    });
}
