// REVOLUTION SLIDER - Before/After

if ( $("#rev-slider-before-after").length ) {

    $("#rev-slider-before-after").show().revolution({
        sliderType:"standard",
        sliderLayout:"auto",
        navigation: {
            keyboardNavigation:"off",
            keyboard_direction: "horizontal",
            mouseScrollNavigation:"off",
            mouseScrollReverse:"default",
            onHoverStop:"off",
            arrows: {
                style:"gyges",
                enable:true,
                hide_onmobile:false,
                hide_onleave:false,
                tmp:'',
                left: {
                    h_align:"center",
                    v_align:"bottom",
                    h_offset:-20,
                    v_offset:0
                },
                right: {
                    h_align:"center",
                    v_align:"bottom",
                    h_offset:20,
                    v_offset:0
                }
            }
        },
        responsiveLevels:[720,720,720,480],
        visibilityLevels:[720,720,720,480],
        gridwidth:[720,720,720,480],
        gridheight:[480,480,480,320],
        spinner:"off",
        stopLoop:"on",
        stopAfterLoops:0,
        stopAtSlide:1,
        debugMode:false,
        fallbacks: {
            simplifyAll:"off",
            nextSlideOnWindowFocus:"off",
            disableFocusListener:false,
        }
    });
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf("MSIE ");

    if(msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./) || ('CSS' in window && 'supports' in window.CSS && !window.CSS.supports('mix-blend-mode', 'screen'))) {
     
         var bgColor = 'rgba(245, 245, 245, 0.5)';
         //$('.rev_slider .tp-caption[data-blendmode]').removeAttr('data-blendmode').css('background', bgColor);
         $('.rev_slider .tp-caption.tp-blendvideo[data-blendmode]').remove();
    }   

    // BEFORE/AFTER INITIALISATION
    RevSliderBeforeAfter($, $("#rev-slider-before-after"), {
        arrowStyles: {
            leftIcon: "fa fa-caret-left",
            rightIcon: "fa fa-caret-right",
            size: "35",
            color: "#ffffff",
            bgColor: "transparent",
            spacing: "10",
            padding: "0",
            borderRadius: "0"
        },
        dividerStyles: {
            width: "1",
            color: "rgba(255, 255, 255, 0.5)"
        },
        onClick: {
            time: "300",
            easing: "Power2.easeInOut",
        },
        cursor: "move",
        carousel: false
    });

}
