//
//  Research Lab Viewlets View More 
//  _______________________________________________________


$('.char-limit-toggle').click(function(e) {

    e.preventDefault();

    if ( !$('.char-limit-toggle').hasClass('viewMoreIsOpen') ) {
    
        $(this).text('view less');

    } else {

        $(this).text('view more');
    }

    $(this).toggleClass('viewMoreIsOpen');
    $('.char-limit-overflow-text').toggle(); 
    $('.char-limit-ellipsis').toggle();

});