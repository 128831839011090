//
//  Hero Jump Navigation
//  _______________________________________________________


// Mobile Menu Logic

// toggle nav menu
 if ( $(window).width() <= 1200 ) {

    $('.hero-nav .jump-title').click(function(e) {

        $(this).toggleClass('heroJumpNavOpen');
        $('.hero-nav .jump-nav').toggleClass('heroJumpNavOpen');

    });  

 }

//close nav menu on link click + jump to anchor link
 $('.jump-nav a[href^="#target-"]').on('click',function (e) {

    $('.hero-nav .jump-title').toggleClass('heroJumpNavOpen');
    $('.hero-nav .jump-nav').toggleClass('heroJumpNavOpen');

    e.preventDefault();

    var target = this.hash;
    var $target = $(target);

    $('html, body').stop().animate({
        'scrollTop': $target.offset().top - 56
    }, 900, 'swing', function () {
        window.location.hash.replace('#','');
    });

});  


// Sticky Navigation
if ( $('.hero-nav.sticky').length ) {

    var $stickyNav = $('.hero-nav.sticky #jumpnav');
    var $stickyNavTop = $('#jumpnav').offset().top;
    var $sticky = 'nav-is-sticky';

    $(window).scroll(function() {

        var $scrollTop = $(window).scrollTop(); // current vertical scroll position from the top

        if ($scrollTop > $stickyNavTop) {

            $stickyNav.addClass($sticky);

        } else {
            
            $stickyNav.removeClass($sticky); 
        }
    });
}
