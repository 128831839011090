//
//  Form Select Link
//  _______________________________________________________


// add option[value] = link for input[select]
(() => {

    let target = document.querySelector('[data-select="link"]');

    if (target) {
        target.addEventListener('change', () => {
            if ( target.selectedIndex!==0 ) {
                window.location.href = target.value;
            } 
        });
    }
})();